<template>
  <div class="wrap">
    <!-- 顶部黑块 -->
    <div class="wrapTop">
      <!-- 视频内容 -->
      <div class="videoBox">
        <div class="wrapTopsCen">
          <div class="room-title">
            <div class="elp">
              {{ detail ? detail.name : '' }}
            </div>
            <div class="detail-ter flex-c">
              <div class="ter-list">
                <div class="terbox">
                  <el-image class="ter-img" :src="courDetail ? courDetail.tImg : ''" fit="cover"></el-image>
                </div>
                <span class="ter">讲师：{{ courDetail ? courDetail.terName : '' }}</span>
              </div>
            </div>
          </div>
          <!-- 视频上方提示 -->
          <div class="wrapTopsCenTop" v-if="tipsShow">
            <!-- 左侧提示 -->
            <div class="wrapTopsCenTopL">
              <img src="@/assets/img/kecheng/tipsIcon.png" />
              <span
                >课程视频版权所有，禁止任何形式得转载！并未经本公司书面许可的使用行为，我公司均保留追究法律责任的权利。</span
              >
            </div>
            <!-- 右侧关闭 -->
            <div class="wrapTopsCenTopR" @click="tipsShow = false">
              <span>关闭</span>
              <img src="@/assets/img/kecheng/classIcon1123.png" />
            </div>
          </div>
          <!-- 中间视频 -->
          <div class="wrapTopsCenCen">
            <!-- 左视频 -->
            <!-- type:视频的类型  src：视频的路径 Controls：确定播放器是否具有用户可以与之交互的控件-->
            <div class="wrapTopsCenCenL">
              <div class="prism-player" id="J_prismPlayers"></div>
            </div>
          </div>
        </div>
        <face-ing-chat
          class="room"
          :playid="id"
          :id="courseId"
          :classId="classId"
          :list="courDetail ? courDetail.faceTeachOutlines : null"
          :IsPurchase="IsPurchase"
        />
      </div>
    </div>
    <!-- 选项栏 -->
    <div class="content">
      <div class="tabTwo m-t-24">
        <div class="tabTwo-text" @click="NavIndex = 1" :class="NavIndex == 1 ? 'active' : ''">
          课程答疑
          <div v-if="NavIndex == 1" class="on"></div>
        </div>
        <div class="tabTwo-text" @click="NavIndex = 2" :class="NavIndex == 2 ? 'active' : ''">
          课程评分
          <div v-if="NavIndex == 2" class="on"></div>
        </div>
      </div>
      <!-- 课程答疑 -->
      <answer-view
        :type="1"
        :IsPurchase="IsPurchase"
        :status="6"
        :courseId="courseId"
        :chapterId="id"
        v-if="NavIndex == 1"
      />
      <!-- 课程评分 -->
      <score-evaluation
        v-if="NavIndex == 2"
        :Belonging="2"
        :chapterId="groupId"
        :sectionId="id"
        :type="10"
        :typeId="courseId"
        :typeNmae="detail.title"
        :IsPurchase="IsPurchase"
      />
    </div>
  </div>
</template>
<script>
import { Know } from '@/api/know'
let know = new Know()
import { getLbClassCourse } from '@/api/mykecheng'
import scoreEvaluation from '@/components/Know/course/scoreEvaluation.vue'
import answerView from '@/components/Know/course/answerView.vue'
import faceIngChat from '@/components/Know/course/faceIngChat.vue'
import { getInfo, getToken } from '@/api/cookies'
export default {
  components: { faceIngChat, scoreEvaluation, answerView },
  data() {
    return {
      userInfo: {},
      // 课程详情信息
      courDetail: {},
      // 课程节详情信息
      detail: {
        courseId: '',
      },
      uservideoConfig: {},
      NavIndex: 1,
      tipsShow: true,
      IsPurchase: false,
      id: null, //章节ID
      groupId: null, //章节组ID
      courseId: null, //录播ID
      classId: null,
      player: {},
    }
  },
  async created() {
    this.userInfo = getInfo()
    this.id = this.$route.query.id
    this.courseId = this.$route.query.courseId
    this.classId =
      this.$route.query.classId && Number(this.$route.query.classId) > 0 ? this.$route.query.classId : null

    // 录播课程详情信息
    await know.getFaceTeachOutlineDetail(this.id).then((res) => {
      this.detail = res.msg
      this.uservideoConfig = res.msg.videoPath
    })
    await know.getPcFaceTeachDetail(this.courseId, this.classId ? this.classId : undefined).then((res) => {
      this.IsPurchase = res.msg.IsPurchase
      this.courDetail = res.msg.faceTeach
      this.$forceUpdate()
    })
    this.crePlayer()
    this.getLbClassCourse()
  },
  methods: {
    async getLbClassCourse() {
      let userId = await localStorage.getItem('userId')
      getLbClassCourse(
        5,
        this.userInfo.id,
        this.userInfo.tenantId,
        this.classId ? this.classId : undefined,
        this.id,
        this.groupId,
        this.courseId,
        this.uservideoConfig
      )
    },
    // 课程概述
    nbvs(val) {
      this.NavIndex = val
    },

    // 播放
    crePlayer() {
      let that = this
      setTimeout(() => {
        that.player = new Aliplayer(
          {
            id: 'J_prismPlayers',
            source: that.uservideoConfig,
            qualitySort: 'asc',
            format: 'mp4',
            mediaType: 'video',
            width: '100%',
            height: '100%',
            autoplay: true,
            isLive: false,
            rePlay: false,
            playsinline: true,
            preload: true,
            controlBarVisibility: 'click',
            showBarTime: 5000,
            useH5Prism: true,
          },
          function (player) {
            console.log('播放器创建好了。')
          }
        )
      }, 100)
    },
    // 添加学习播放记录
  },
}
</script>
<style lang="less" scoped>
.wrap {
  width: 100%;
  height: 100%;
  // 顶部黑块
  .wrapTop {
    width: 100%;
    overflow: hidden;
    background: #222222;
    border-radius: 0px 0px 0px 0px;
    // 上方课程名
    .wrapTopsTop {
      width: 100%;
      height: 58px;
      line-height: 58px;
      background: #383838;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
      padding: 18px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // 左侧标题
      .wrapTopsTopL {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        // 图片

        // 文字标题
        p {
          width: auto;
          height: auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #aaaaaa;
          span {
            color: #ffffff;
          }
        }
      }
      // 右侧返回课程介绍
      .wrapTopsTopR {
        width: auto;
        height: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        // 文字
        span {
          width: auto;
          height: auto;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
        // 返回图片
        img {
          width: 13px;
          height: 11px;
          margin-right: 5px;
        }
      }
    }
    .videoBox {
      height: 553px;
      margin: 32px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      // 视频内容
      .wrapTopsCen {
        width: 880px;
        margin-right: 20px;
        position: relative;
        // 视频上方提示
        .wrapTopsCenTop {
          width: 100%;
          height: 40px;
          background: #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          top: 58px;
          z-index: 99;
          // 左侧提示
          .wrapTopsCenTopL {
            width: auto;
            height: 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin: 0px 10px 0px 10px;
            }
            span {
              width: auto;
              height: auto;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #f15817;
            }
          }
          // 右侧关闭
          .wrapTopsCenTopR {
            width: auto;
            height: 12px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            span {
              width: auto;
              height: auto;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #a8abbe;
            }
            img {
              width: 11px;
              height: 11px;
              margin: 0px 10px 0px 5px;
            }
          }
        }
        // 中间视频
        .wrapTopsCenCen {
          width: 100%;
          height: 495px;
          margin-top: 1px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // 左视频
          .wrapTopsCenCenL {
            width: 100%;
            height: 100%;
            background: #f2f2f2;
            .prism-player {
              width: 100%;
              height: 100%;
            }
            video {
              width: 100%;
              height: 100%;
            }
          }
          // 右头像/聊天
          .wrapTopsCenCenR {
            width: 25%;
            height: 100%;
            // 讲师头像
            .wrapTopsCenCenRT {
              width: 100%;
              height: 190px;
              background: #ffffff;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              img {
                width: 135px;
                height: 113px;
                margin-top: 26px;
              }
              span {
                width: auto;
                height: auto;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #6a6a6a;
              }
            }
            // 聊天框
            .wrapTopsCenCenRC {
              width: 100%;
              height: 346px;
              // 聊天框顶部
              .wrapTopsCenCenRCa {
                width: 100%;
                height: 40px;
                background: #464646;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #efefef;
              }
              // 聊天框内容
              .wrapTopsCenCenRCb {
                width: 100%;
                height: 260px;
                overflow: auto;
                // overflow-y:scroll;
                // overflow-x:hidden
                // 内容盒子
                .wrapTopsCenCenRCbs {
                  width: 85%;
                  height: 32px;
                  margin: 20px auto;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  // 左侧头像
                  .wrapTopsCenCenRCbsL {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 10px;
                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }
                  // 右侧昵称/留言
                  .wrapTopsCenCenRCbsR {
                    width: auto;
                    height: 32px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    span {
                      width: auto;
                      height: auto;
                      font-size: 12px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #b0b0b0;
                    }
                    p {
                      width: auto;
                      height: auto;
                      font-size: 12px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #ffffff;
                    }
                  }
                }
              }
              // 聊天框底部
              .wrapTopsCenCenRCc {
                width: 100%;
                height: 46px;
                background: #5d5d5d;
                display: flex;
                justify-content: center;
                align-items: center;
                // 内容盒子
                .wrapTopsCenCenRCcs {
                  width: 85%;
                  height: 30px;
                  background: #7a7a7a;
                  border-radius: 15px;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  // 输入框
                  input {
                    width: 70%;
                    height: 100%;
                    border: none;
                    outline: none;
                    background: #7a7a7a;
                    color: #ffffff;
                  }
                  // 修改输入框预输入文字
                  input::-webkit-input-placeholder {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #efefef;
                  }
                  // 发送
                  div {
                    width: 24%;
                    height: 100%;
                    background: #a1a1a1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0px 15px 15px 0px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #efefef;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // 直播课内容
  .warpCon {
    width: 100%;
    // height: 920px;
    height: auto;
    margin-bottom: 30px;
    background: #f5f6fa;
    overflow: hidden;
    // 缩宽
    .warpCons {
      width: 65%;
      height: 100%;
      margin: auto;
      overflow: hidden;
      margin-bottom: 50px;
      .warpConss {
        width: 100%;
        height: auto;
        // 单个内容
        .warpConske {
          width: 100%;
          height: 76px;
          margin-top: 30px;
          background: #ffffff;
          border: 1px solid #ffc3bb;
          border-radius: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // 左侧标题
          .warpConskeL {
            width: auto;
            height: 19px;
            margin-left: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 21px;
              height: 19px;
              margin-right: 16px;
              cursor: pointer;
            }
            p {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #27323f;
              cursor: pointer;
              span {
                width: auto;
                height: auto;
                margin-left: 20px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #7b8196;
              }
            }
          }
          // 右侧展开/收起
          .warpConskeR {
            width: auto;
            height: 15px;
            margin-right: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            img {
              width: 9px;
              height: 7px;
              margin-left: 7px;
            }
            span {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7b8196;
            }
          }
        }
        // 单个内容展开项盒子
        .warpConskexq {
          width: 100%;
          height: auto;
          background: #ffffff;
          padding: 20px;
          .warpConskexqs {
            width: 1175px;
            height: 50px;
            background: #f7f7f7;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // 左侧
            .warpConskexqsL {
              width: auto;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                width: 4px;
                height: 50px;
                background: #dddddd;
                margin-right: 27px;
              }
              span {
                width: auto;
                height: auto;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                cursor: pointer;
              }
            }
            // 右侧
            .warpConskexqsR {
              width: 320px;
              height: 32px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                width: 90px;
                height: 32px;
                margin-right: 10px;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .apple {
      width: 1200px;
      height: 100%;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .appleLeft {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #ffffff;
        .appleLefts {
          width: 100%;
          height: auto;
          /deep/ img {
            width: 100% !important;
          }
        }
      }
    }
  }
  .room {
    width: 320px;
  }
}
/* 章节名称 */
.room-title {
  height: 58px;
  background: #383838;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
/* 讲师 */
.detail-ter {
  flex-shrink: 0;
  .ter {
    color: #fff;
  }
}
.content {
  width: 1220px;
  background: #ffffff;
  margin: 20px auto;
}
</style>
