<template>
  <div class="bofangChater">
    <div class="box-item"><i class="iconfont icon-a-zu335"></i>课程列表</div>
    <div class="chat-item" v-for="(item, index) in CourseList" :key="index">
      <div
        class="item-title"
        :class="playid == item.id ? 'active' : ''"
        @click.stop="check(item, 1)"
      >
        <div class="dis_flex">
          <i class="iconfont icon-a-Union21"></i>
          <div class="title w-b-100 ellipsis">
            {{ item.name }}
          </div>
        </div>
        <!-- <div v-if="playid == item.id" class="title ellipsis shrink0">
          正在播放
        </div> -->
        <!--   <div v-else></div>
        <div class="video iconfont icon-a-Union21" @click.stop="goLive(item, item.isLive)"></div> -->
      </div>
      <div class="but-list">
        <div
          class="keep-learn"
          v-if="item.isHasContantJY == 'true'"
          @click="ktData(item, 4)"
        >
          随堂讲义
        </div>
        <div
          class="keep-learn"
          v-if="item.isHasContant == 'true'"
          @click="cgeckTask(item)"
        >
          随堂测试
        </div>
        <div
          class="keep-learn"
          v-if="item.isHasDoc == 'true'"
          @click="ktData(item, 2)"
        >
          随堂资料
        </div>
        <div class="keep-learn" @click.stop="check(item, 1)">观看课程</div>
        <div
          class="keep-learn"
          @click="cgeckTask(item)"
          v-if="item.isHasContant == 'true'"
        >
          课后作业
        </div>
        <div
          class="keep-learn"
          @click="ktData(item)"
          v-if="item.isHasDoc == 'true'"
        >
          随堂资料
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getClassCourseById } from "@/api/home";
import { IsLive } from "@/api/emun";
import { getInfo, getToken } from "@/api/cookies";
import { Know } from "@/api/know";
let know = new Know();
import { AnswerClient } from "@/api/task/answerClient";
let answerClient = new AnswerClient();
export default {
  data() {
    return {
      userInfo: null,
      IsLive: IsLive,
      CourseList: [],
      dialogVisible: false,
      openIndex: 0,
    };
  },
  props: ["id", "playid", "list", "IsPurchase", "classId"],
  created() {
    this.userInfo = getInfo();
    if (this.list) {
      this.CourseList = this.list;
      for (let item of this.CourseList) {
        if (!item.list) {
          item.list = item.videoList;
        }
      }
    } else {
      know.getPcFaceTeachDetail(this.id).then((res) => {
        this.CourseList = res.msg.faceTeach.faceTeachOutlines;
      });
    }
  },
  methods: {
    tChuang() {
      this.dialogVisible = false;
    },
    stop() {
      return;
    },
    //观看回放
    check(item, groupId) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      let sub = {
        id: item.id,
        uservideoConfig: item.videoConfig,
        courseId: this.id,
        classId: this.classId,
        groupId: groupId.id,
        typebs: 10,
      };
      let data = Vue.prototype.export(sub);
      this.$router.push(`/faceCouser/faceChatView?${data}`);
    },
    // 课堂作业
    cgeckTask(item) {
      answerClient.selectPaperSubjectList(item.sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            item.title,
            item.sId,
            2,
            10,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.id,
            item.id,
            this.classId
          );
        }
      });
    },
    //随堂资料
    ktData(item, type) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (!this.IsPurchase) {
        this.dialogVisible = true;
        return;
      }
      window.open(
        `/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`,
        "_blank"
      );
      // this.$router.push(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`);
    },
  },
};
</script>
<style lang="less" scoped>
.bofangChater {
  background: #383838;
  border-radius: 8px 8px 8px 8px;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  width: 100%;
  .box-item {
    padding: 0px 20px;
    align-items: center;
    display: flex;
    height: 48px;
    line-height: 48px;
    background: #444444;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ffffff;
    .iconfont {
      font-size: 16px;
      margin-right: 3px;
    }
    margin-bottom: 4px;
  }
}
//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
/deep/ .el-collapse {
  border: none !important;
  .el-collapse-item__header {
    padding: 0px 20px;
    min-height: 48px;
    height: auto;
    background: #555555;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    line-height: 48px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ffffff;
    border: none;
    margin-top: 4px;
  }
  .el-collapse-item__content {
    padding: 0px;
  }
  .el-collapse-item__wrap {
    border: none;
  }
}
/* 试听 */
.audition {
  width: 48px;
  height: 20px;
  background: #ffedeb;
  border-radius: 2px 2px 2px 2px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ff5e51;
  line-height: 20px;
  margin-left: 8px;
  text-align: center;
}
/* 节 */
.chat-item {
  width: 100%;
  border-bottom: 1px solid #555555;
  padding: 16px 20px;
  background: #383838;
  .active {
    color: #ff5e51;
    .iconfont,
    .title {
      color: #ff5e51 !important;
    }
  }
  .item-title {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    .iconfont {
      font-size: 14px;
      margin-right: 12px;
      color: #ffffff;
      flex-shrink: 0;
    }
    .title {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;
    }
  }

  .but-list {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    .keep-learn {
      margin-left: 12px;
      height: 24px;
      padding: 0px 12px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #ffffff;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
    }
  }
}
</style>
